<div fxLayout="column">
    <div *ngFor="let control of formControl.controls; let idx = index" fxLayout fxLayoutGap="24px">
        <mat-form-field fxFlex>
            <mat-label>{{ label }}</mat-label>
            <input matInput [formControl]="control" type="text" autocomplete="off" />
        </mat-form-field>
        <ng-container *ngIf="showIcons">
            <dsh-bi
                *ngIf="formControl.controls.length > 1 || formControl.controls[0].value"
                icon="x"
                size="lg"
                class="remove-icon action-icon"
                (click)="removeControl(idx)"
            ></dsh-bi>
        </ng-container>
    </div>
    <ng-container *ngIf="showIcons">
        <dsh-bi icon="plus" size="lg" class="action-icon" fxFlexAlign="end" (click)="addControl()"></dsh-bi>
    </ng-container>
</div>
