import { Component, Injector } from '@angular/core';
import { provideValueAccessor, WrappedFormControlSuperclass } from '@s-libs/ng-core';

@Component({
    selector: 'dsh-order-id-field',
    templateUrl: 'order-id-field.component.html',
    providers: [provideValueAccessor(OrderIdFieldComponent)],
})
export class OrderIdFieldComponent extends WrappedFormControlSuperclass<string[]> {
    constructor(injector: Injector) {
        super(injector);
    }
}
