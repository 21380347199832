<div class="dsh-mobile-menu" fxLayout="column">
    <div class="dsh-enu-nav-items" fxFlex fxLayout="column" fxLayoutGap="16px">
        <dsh-mobile-menu-nav-item
            *ngFor="let link of sectionLinks$ | async"
            [routerLink]="link.path"
            routerLinkActive
            [routerLinkActiveOptions]="{ exact: link?.exact }"
            #rla="routerLinkActive"
            [active]="rla.isActive"
            (click)="menuItemSelected.emit()"
            >{{ link.label }}</dsh-mobile-menu-nav-item
        >
    </div>
    <mat-divider></mat-divider>
    <dsh-mobile-user-bar></dsh-mobile-user-bar>
</div>
