<dsh-filter
    *transloco="let t; scope: 'invoice-status-filter'; read: 'invoiceStatusFilter'"
    [active]="isActive"
    [label]="t('label')"
    [activeLabel]="savedValue | invoiceStatusLabel | async"
    [content]="content"
    (save)="save()"
    (clear)="clear()"
>
</dsh-filter>
<ng-template #content>
    <dsh-invoice-status-field [formControl]="control"></dsh-invoice-status-field>
</ng-template>
