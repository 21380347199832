<div
    *transloco="let t; scope: 'api-model-details'; read: 'apiModelDetails.payoutTool'"
    fxLayout="column"
    fxLayoutGap="24px"
>
    <dsh-details-item [title]="t('currency')">
        {{ payoutTool?.currency }}
    </dsh-details-item>
    <dsh-payout-tool-details [payoutToolDetails]="payoutTool?.details"></dsh-payout-tool-details>
</div>
