<dsh-filter
    *transloco="let t; scope: 'orders-filter'; read: 'ordersFilter'"
    [active]="isActive"
    [label]="t('label')"
    [activeLabel]="t('activeLabel') | listLabel: savedValue:0"
    [content]="content"
    (save)="save()"
    (clear)="clear()"
></dsh-filter>
<ng-template #content><dsh-order-id-field [formControl]="control"></dsh-order-id-field></ng-template>
