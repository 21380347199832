<div class="base-dialog" fxLayout="column" fxLayoutGap="24px" fxFlex="grow">
    <div class="base-dialog-title">
        <div [dshNoContent]="titleBlock">
            <ng-content select="dshBaseDialogTitle"></ng-content>
            <ng-content select="[dshBaseDialogTitle]"></ng-content>
        </div>
    </div>
    <ng-container *ngTemplateOutlet="contentDividerContent"></ng-container>
    <div class="base-dialog-content" [dshNoContent]="noContent">
        <ng-content></ng-content>
    </div>
    <ng-container *ngIf="!noActions">
        <ng-container *ngTemplateOutlet="contentDividerContent"></ng-container>
        <dsh-actions>
            <ng-content select="dshBaseDialogActions"></ng-content>
            <ng-content select="[dshBaseDialogActions]"></ng-content>
        </dsh-actions>
    </ng-container>
</div>

<ng-template #contentDividerContent>
    <mat-divider *ngIf="hasDivider"></mat-divider>
</ng-template>

<ng-template #noContent></ng-template>

<ng-template #titleBlock>
    <div fxLayout="row" fxLayoutAlign="space-between">
        <div class="dsh-headline">{{ title }}</div>
        <dsh-bi class="base-dialog-title-close" icon="x" size="lg" (click)="cancelDialog()"></dsh-bi>
    </div>
</ng-template>
