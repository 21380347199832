<div
    *transloco="let t; scope: 'api-model-details'; read: 'apiModelDetails.bankAccount'"
    gdColumns="1fr"
    gdColumns.gt-sm="1fr 1fr 1fr"
    gdGap="24px"
>
    <dsh-details-item [title]="t('bankName')">
        {{ bankAccount?.bankName }}
    </dsh-details-item>
    <dsh-details-item [title]="t('bankBik')">
        {{ bankAccount?.bankBik }}
    </dsh-details-item>
    <dsh-details-item [title]="t('bankPostAccount')">
        {{ bankAccount?.bankPostAccount }}
    </dsh-details-item>
    <dsh-details-item [title]="t('account')">
        {{ bankAccount?.account }}
    </dsh-details-item>
</div>
