<app-spring-spinner
    *ngIf="activeSpinner === SpinnerType.Spring"
    [animationDuration]="animationDuration"
    [size]="size"
    [color]="color"
></app-spring-spinner>

<app-semipolar-spinner
    *ngIf="activeSpinner === SpinnerType.Semipolar"
    [animationDuration]="animationDuration"
    [size]="40"
    [color]="color"
></app-semipolar-spinner>

<app-fulfilling-bouncing-circle-spinner
    *ngIf="activeSpinner === SpinnerType.FulfillingBouncingCircle"
    [animationDuration]="animationDuration"
    [size]="size"
    [color]="color"
></app-fulfilling-bouncing-circle-spinner>

<app-radar-spinner
    *ngIf="activeSpinner === SpinnerType.Radar"
    [animationDuration]="animationDuration"
    [size]="size"
    [color]="color"
></app-radar-spinner>

<app-trinity-rings-spinner
    *ngIf="activeSpinner === SpinnerType.TrinityRings"
    [animationDuration]="animationDuration"
    [size]="size"
    [color]="color"
></app-trinity-rings-spinner>

<app-half-circle-spinner
    *ngIf="activeSpinner === SpinnerType.HalfCircle"
    [animationDuration]="animationDuration"
    [size]="size"
    [color]="color"
></app-half-circle-spinner>

<app-scaling-squares-spinner
    *ngIf="activeSpinner === SpinnerType.ScalingSquares"
    [animationDuration]="animationDuration"
    [size]="size"
    [color]="color"
></app-scaling-squares-spinner>

<app-swapping-squares-spinner
    *ngIf="activeSpinner === SpinnerType.SwappingSquares"
    [animationDuration]="animationDuration"
    [size]="size"
    [color]="color"
></app-swapping-squares-spinner>
